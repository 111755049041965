import React, { useState, useReducer, useEffect, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, UNSAFE_DataRouterStateContext, useLocation, Outlet, Navigate } from "react-router-dom";

import { pathToRegexp } from "path-to-regexp";

import { useImmerReducer } from "use-immer";

import StateContext from "./StateContext";
import DispatchContext from "./DispatchContext";
import LoadingDotsIcon from "./components/LoadingDotsIcon";

// Lazy-loaded components
const Header = lazy(() => import("./components/Header"));
const HomeGuest = lazy(() => import("./components/HomeGuest"));
const Footer = lazy(() => import("./components/Footer"));
const About = lazy(() => import("./components/About"));
const MobileMessage = lazy(() => import("./components/MobileMessage"));
const Terms = lazy(() => import("./components/Terms"));
const Home = lazy(() => import("./components/Home"));
const CreatePost = lazy(() => import("./components/CreatePost"));
const ViewSinglePost = lazy(() => import("./components/ViewSinglePost"));
const FlashMessages = lazy(() => import("./components/FlashMessages"));
const Profile = lazy(() => import("./components/Profile"));
const EditPost = lazy(() => import("./components/EditPost"));
const NotFound = lazy(() => import("./components/NotFound"));
const EditResponse = lazy(() => import("./components/EditResponse"));
const Search = lazy(() => import("./components/Search"));
const StartMixer = lazy(() => import("./components/StartMixer"));
const FillSurveySingle = lazy(() => import("./components/FillSurveySingle"));
const ThankYou = lazy(() => import("./components/ThankYou"));
const MySideNav = lazy(() => import("./components/MySideNav"));
const GEMSideNav = lazy(() => import("./components/GEMSideNav"));
const SelectModel = lazy(() => import("./components/SelectModel"));
const ModelDetails = lazy(() => import("./components/ModelDetails"));
const ModelSetup = lazy(() => import("./components/ModelSetup"));
const ModelDash = lazy(() => import("./components/ModelDash"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const ModelSettings = lazy(() => import("./components/ModelSettings"));
const ModelResponses = lazy(() => import("./components/ModelResponses"));
const ModelDetailCreator = lazy(() => import("./components/ModelDetailCreator"));
const GEMAnalysis = lazy(() => import("./components/report001/GEM-Analysis"));
const SERVPERFAnalysis = lazy(() => import("./components/report001/SERVPERF-Analysis"));
const GlobalSettings = lazy(() => import("./components/GlobalSettings"));

const GEMAnalysis1 = lazy(() => import("./components/report001/GEM-Analysis_One"));
const HeaderMobile = lazy(() => import("./components/HeaderMobile"));
const Landing = lazy(() => import("./components/Landing"));
const FooterLanding = lazy(() => import("./components/FooterLanding"));
const LandingGEM = lazy(() => import("./components/LandingGEM"));
const VerifyEmail = lazy(() => import("./components/VerifyEmail"));
const RequestResetPassword = lazy(() => import("./components/RequestResetPassword"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));

const NewSurvey = lazy(() => import("./components/NewSurvey"));
const GoogleForms = lazy(() => import("./components/GoogleForms"));
const NewDash = lazy(() => import("./components/NewDash"));
const NewResponses = lazy(() => import("./components/NewResponses"));
const NewDashboard = lazy(() => import("./components/NewDashboard"));
const Customize = lazy(() => import("./components/Customize"));
const SurveyPlayground = lazy(() => import("./components/SurveyPlayground"));

const ViewFeedbacks = lazy(() => import("./components/ViewFeedbacks"));
const ViewFeedbacksOpenAI = lazy(() => import("./components/ViewFeedbacksOpenAI"));
// const NewTestSurvey = lazy(() => import("./components/NewTestSurvey"));
// const MinimalSurveyComponent = lazy(() => import("./components/MinimalSurveyComponent"));
const OpenVisuals = lazy(() => import("./components/OpenVisuals"));

import { CSSTransition } from "react-transition-group";

import Axios from "axios";
const EditSurvey = lazy(() => import("./components/EditSurvey"));
const FormPlayground = lazy(() => import("./components/FormPlayground"));
const ResponsePlayground = lazy(() => import("./components/ResponsePlayground"));
const TablePlayground = lazy(() => import("./components/TablePlayground"));
const SurveyAnalysisCard = lazy(() => import("./components/SurveyAnalysisCard"));
const FormDemo = lazy(() => import("./components/FormDemo"));
const URLInputPage = lazy(() => import("./components/URLInputPage"));
const DemoFormPage = lazy(() => import("./components/DemoFormPage"));
const SelectProduct = lazy(() => import("./components/SelectProduct"));
const EditMagicSurvey = lazy(() => import("./components/EditMagicSurvey"));
const URLInputPageLoggedIn = lazy(() => import("./components/URLInputPageLoggedIn"));
const MagicFormPage = lazy(() => import("./components/MagicFormPage"));
const HomeSelect = lazy(() => import("./components/HomeSelect"));

const LoginPage = lazy(() => import("./components/LoginPage"));

import "./main.css";
import URLInputPageBeta from "./components/URLInputPageBeta";

const mode = "development";
// Axios.defaults.baseURL = 'https://www.pythanks.net:8086'
if (mode === "production") {
  Axios.defaults.baseURL = "https://www.pythanks.net:8086";
} else {
  Axios.defaults.baseURL = "https://www.keenceo.com:8085";
}
console.log("default base", Axios.defaults.baseURL);

function Main() {
  const mode = "development";
  mode === "production" && (console.log = () => {});

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("complexappToken")),
    flashMessages: [],
    user: {
      token: localStorage.getItem("complexappToken"),
      username: localStorage.getItem("complexappUsername"),
      avatar: localStorage.getItem("complexappAvatar"),
      pythanksClientId: localStorage.getItem("pythanksClientId"),
      email: localStorage.getItem("email"),
      newClient: localStorage.getItem("newClient"),
      emailValidated: localStorage.getItem("emailValidated"),
      plan: localStorage.getItem("plan"),
    },
    isSearchOpen: false,
    showHeader: true,
    activeSurveyId: null,
    activeMenuItem: "analysis",
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;
        draft.user = action.data;
        return;
      case "logout":
        draft.loggedIn = false;
        return;
      case "flashMessage":
        draft.flashMessages.push(action.value);
        return;
      case "openSearch":
        draft.isSearchOpen = true;
        return;
      case "closeSearch":
        draft.isSearchOpen = false;
        return;
      case "setActiveSurveyId":
        draft.activeSurveyId = action.value;
        return;
      case "setActiveMenuItem":
        draft.activeMenuItem = action.value;
        return;
      case "setNewClient":
        draft.user.newClient = action.value;
        localStorage.setItem("newClient", action.value);
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    const splash = document.getElementById("splash");
    if (splash) {
      splash.style.display = "none";
    }
  }, []);

  useEffect(() => {
    if (state.loggedIn) {
      console.log("see user: ", state.user);
      localStorage.setItem("complexappToken", state.user.token);
      localStorage.setItem("complexappUsername", state.user.username);
      localStorage.setItem("complexappAvatar", state.user.avatar);
      localStorage.setItem("pythanksClientId", state.user.pythanksClientId);
      localStorage.setItem("email", state.user.email);
      localStorage.setItem("newClient", state.user.newClient);
      localStorage.setItem("emailValidated", state.user.emailValidated);
      localStorage.setItem("plan", state.user.plan);
    } else {
      localStorage.removeItem("complexappToken");
      localStorage.removeItem("complexappUsername");
      localStorage.removeItem("complexappAvatar");
      localStorage.removeItem("pythanksClientId");
      localStorage.removeItem("email");
      localStorage.removeItem("newClient");
      localStorage.removeItem("emailValidated");
      localStorage.removeItem("surveyJson");
      localStorage.removeItem("surveyCriteria");
      localStorage.removeItem("surveyCompany");
      localStorage.removeItem("surveyProblem");
      // localStorage.removeItem("clientPlan");
    }
  }, [state.loggedIn]);

  useEffect(() => {
    if (state.loggedIn) {
      const ourRequest = Axios.CancelToken.source();
      const fetchResults = async () => {
        try {
          const response = await Axios.post("/checkToken", { token: state.user.token }, { cancelToken: ourRequest.token });
          // console.log("check token response: ", response.data);
          if (!response.data) {
            dispatch({ type: "logout" });
            dispatch({ type: "flashMessage", value: "Your session has expired. Please log in again." });
          }
        } catch (err) {
          console.log("There was a problem or the request was cancelled.");
        }
      };
      fetchResults();
      return () => ourRequest.cancel();
    }
  }, []);

  const isMobile = window.innerWidth <= 767;

  const Layout = () => {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  };

  const ReportLayout = () => {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  };

  const MetaLayout = () => {
    return (
      <>
        <Header />
        <MySideNav />
        <Outlet />
      </>
    );
  };

  const FreeLayout = () => {
    const isMobile = window.innerWidth <= 767;
    return (
      <>
        {isMobile ? (
          <MobileMessage /> // Render MobileMessage directly on mobile
        ) : (
          <>
            <Header />
            <Outlet />
          </>
        )}
      </>
    );
  };

  const LandingLayout = () => {
    return (
      <>
        {!isMobile && <Header />}
        {isMobile && <HeaderMobile />}
        <Outlet />
      </>
    );
  };

  const NoLayout = () => {
    const isMobile = window.innerWidth <= 767;
    return (
      <>
        {isMobile ? (
          <MobileMessage /> // Render MobileMessage directly on mobile
        ) : (
          <Outlet />
        )}
      </>
    );
  };

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Suspense fallback={<LoadingDotsIcon />}>
            <FlashMessages messages={state.flashMessages} />
            <Routes>
              <Route element={<FreeLayout />}>
                {/* <Route path="/" element={state.loggedIn ? <Home /> : <HomeSelect />} /> */}
                {/* <Route path="/" element={isMobile ? <MobileMessage /> : state.loggedIn ? <Home /> : <HomeSelect />} /> */}

                <Route path="/" element={state.loggedIn ? <Home /> : <Navigate to="/start-magic" />} />

                <Route path="/about-us" element={<About />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/select-model" element={<SelectModel />} />
                <Route path="/select-product" element={<SelectProduct />} />
                <Route path="/model-details/:surveytemplateid" element={<ModelDetails />} />
                <Route path="/model-setup/:surveytemplateid" element={<ModelSetup />} />
                <Route path="/model-responses/:surveyid" element={<NewResponses />} />
                <Route path="/model-dash/:surveyid/:tab?" element={<ModelDash />} />
                <Route path="/model-detail-creator/*" element={<ModelDetailCreator />} />
                <Route path="/global-settings/" element={<GlobalSettings />} />
                <Route path="/customize/:templateid" element={<Customize />} />
                <Route path="/edit-survey/:surveyid" element={<EditSurvey />} />
                <Route path="/view-feedback/:surveyid/:filter?" element={<ViewFeedbacks />} />
                <Route path="/start-magic-survey" element={<URLInputPageLoggedIn />} />
                {/* <Route path="/start-magic" element={<URLInputPage />} /> */}
              </Route>

              <Route element={<LandingLayout />}>
                <Route path="/landing" element={<LandingGEM />} />
              </Route>

              <Route element={<NoLayout />}>
                <Route path="/register" element={<HomeGuest />} />
                <Route path="/validate-email/:token" element={<VerifyEmail />} />
                <Route path="/request-reset-password/" element={<RequestResetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/survey-playground" element={<SurveyPlayground />} />
                <Route path="/form-playground/:customer?" element={<FormPlayground />} />
                <Route path="/response-playground" element={<ResponsePlayground />} />
                <Route path="/table-playground" element={<TablePlayground />} />
                <Route path="/survey-analysis-card" element={<SurveyAnalysisCard />} />
                <Route path="/form-demo/:customer?" element={<FormDemo />} />
                <Route path="/start-magic" element={<URLInputPage />} />
                <Route path="/magic-survey" element={<DemoFormPage />} />
                <Route path="/edit-magic-survey/:surveyid" element={<EditMagicSurvey />} />
                <Route path="/magic-survey-form" element={<MagicFormPage />} />
                <Route path="/start-magic-beta" element={<URLInputPageBeta />} />
              </Route>

              <Route element={<MetaLayout />}>
                <Route path="/sidenav" element={<MySideNav />} />
              </Route>

              <Route element={<ReportLayout />}>
                <Route path="/dashboard/:username/*" element={<Dashboard />} />
                <Route path="/model-settings/:surveyid" element={<ModelSettings />} />
                <Route path="/GEM/analysis1/:surveyid/:sectionid" element={<GEMAnalysis />} />
                <Route path="/SERVPERF/analysis/:surveyid/:sectionid" element={<SERVPERFAnalysis />} />
                <Route path="/GEM/analysis/:surveyid/:sectionid" element={<GEMAnalysis1 />} />
              </Route>

              <Route path="/start-survey/:id/:demoid?" element={<StartMixer />} />
              <Route path="/type1/:id/:sid/:demoid?" element={<FillSurveySingle />} />
              <Route path="/thank-you/:demoid?" element={<ThankYou />} />
              <Route path="/open-visuals/:surveyid/:accessid" element={<OpenVisuals />} />
              {/* <Route path="/new-minimal" element={<MinimalSurveyComponent />} /> */}
              <Route path="/google-forms" element={<GoogleForms />} />
              <Route path="/googled" element={<ThankYou />} />
              <Route path="/new-dash" element={<NewDash />} />
              <Route path="/new-dashboard" element={<NewDashboard />} />
              <Route path="/view-feedback-openai/:surveyid/:filter?/:timekey?" element={<ViewFeedbacksOpenAI />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <CSSTransition timeout={330} in={state.isSearchOpen} classNames="search-overlay" unmountOnExit>
              <Search />
            </CSSTransition>
          </Suspense>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.querySelector("#app"));
root.render(<Main />);

if (module.hot) {
  module.hot.accept();
}
